import React, {FC} from 'react'
import cls from "../forms.module.scss"
import ErrorMessages from "./ErrorMessages";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import {setFormElementValue} from "../../../../store/formbuilder";

interface IProps {
    formId: number,
    index: number,
    element: {
        required: boolean,
        label: string,
        description: string,
        descriptionShow : boolean,
        className: string,
        name: string,
        allowedFileExtension: string,
        allowedFileSize: string,
        multiple: boolean,
    }
}

const InputFile: FC<IProps> = (
    {
        formId,
        index,
        element: {
            required = false,
            label = "",
            description = "",
            descriptionShow = false,
            className = "",
            name = "",
            allowedFileExtension = "",
            allowedFileSize = "",
            multiple = false,
        }
    }
) => {
    const reduxValue = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].value || "")
    const errors = useSelector((state: IState) => state.formBuilder.errors)
    const dispatch = useDispatch();

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0]
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.addEventListener('load', () => {
                dispatch(setFormElementValue({
                    id: formId,
                    index,
                    fieldsForChange: {
                        value: file
                    }
                }))
                // event.target.value = null;
            })
        }
    }

    const handleClasses = {
        true: `${className} ${cls.warning}`,
        false: className,
    }

    const handleRequired = {
        true: <span className={cls.required}>*</span>,
        false: null
    }

    const handleDescription = {
        true: <div className={cls.description}>
            <span>?</span>
            <div className={cls.info_text_wrapper}>
                <div className={cls.info_text}>{description}</div>
            </div>
        </div>,
        false: null
    }

    return <div className={cls.form_input}>
        <div className={cls.label}>
            <label htmlFor={name}>
                {label}
            </label>
            {handleRequired[`${required}`]}
            {handleDescription[`${descriptionShow}`]}
        </div>
        <input
            id={name}
            name={name}
            type="file"
            className={handleClasses[`${!!errors[name]}`]}
            accept={allowedFileExtension}
            size={+allowedFileSize}
            multiple={multiple}
            onChange={handleChangeValue}
        />
        <ErrorMessages errors={errors[name]}/>
    </div>
}
export default InputFile
